import React, { useState } from "react";
import { AgCharts } from "ag-charts-react";

function getData() {
  return [
    { month: "Jan", success: 222, failed: 12 },
    { month: "Feb", success: 180, failed: 21 },
    { month: "Mar", success: 280, failed: 9 },
    { month: "Apr", success: 300, failed: 26 },
    { month: "May", success: 350, failed: 11 },
    { month: "Jun", success: 420, failed: 10 },
    { month: "Jul", success: 300, failed: 8 },
    { month: "Aug", success: 270, failed: 55 },
    { month: "Sep", success: 260, failed: 12 },
    { month: "Oct", success: 385, failed: 11 },
    { month: "Nov", success: 320, failed: 10 },
    { month: "Dec", success: 330, failed: 23 },
  ];
}

const AreaChart = (props) => {
  const [options, setOptions] = useState({
    data: props.data,
    series: [
      {
        type: "area",
        xKey: "date",
        yKey: "success",
        yName: "Success",
      },
      {
        type: "area",
        xKey: "date",
        yKey: "failed",
        yName: "Failed",
      },
    ],
  });

  return (
    <AgCharts options={options} style={{ height: "70%", width: "100%" }} />
  );
};

export default AreaChart;
