import { Segmented, Tabs } from "antd";
import AppLogo from "../../static/images/logo.png";
import "./MasterLayout.scss";
import Typography from "../Typography";
import { PieChartOutlined, LineChartOutlined } from "@ant-design/icons";
import PieChart from "../PieChart";
import LineChart from "../LineChart";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import loadingLottie from "../../static/lottie/loading.json";
import { apiGet } from "../../services/apiService";
import BarChart from "../BarChart";
import AreaChart from "../AreaChart";

const items = [
  {
    key: "1",
    label: "Platform",
  },
  {
    key: "2",
    label: "Hotel",
  },
];

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loadingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function MasterLayout(props) {
  const [activeView, setActiveView] = useState("App");
  const [analytics, setAnalytics] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    fetchAnalyticsReport();
  }, []);
  const fetchAnalyticsReport = async () => {
    setLoading(true);
    const response = await apiGet(
      "https://stage-apiplatform.finkraftai.com/api/finalytics/insight/hotel/email"
    );
    console.log("response", response);
    setAnalytics(response);
    setLoading(false);
  };

  const getDomainBasedLoginInfo = (loginActivity) => {
    let chartData = [];
    loginActivity.forEach((loginActivity) => {
      let loginDomain = loginActivity.email.split("@")?.[1];
      console.log("login domain", loginDomain);
      let findIndex = chartData.findIndex((item) => item.asset === loginDomain);
      if (findIndex !== -1) {
        chartData[findIndex].amount += 1;
      } else {
        chartData.push({
          asset: loginDomain,
          amount: 1,
        });
      }
    });
    return chartData;
  };
  return isLoading ? (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie options={lottieOptions} height={144} width={144} />
    </div>
  ) : (
    <div className="MasterLayout">
      <div className="HeaderContainer">
        <div className="HeaderLeft">
          <img src={AppLogo} alt="Logo" />
          <div className="MenuContainer">
            <Tabs
              tabBarStyle={{
                borderBottom: "none",
                margin: 0, // Remove the underline border
              }}
              defaultActiveKey="1"
              items={items}
              onChange={(tab) => setActiveView(tab)}
            />
          </div>
        </div>
        <div className="HeaderRight"></div>
      </div>
      <div className="AppContentLayout">
        {/* <div className="SegmentContainer">
          <Segmented
            options={["App", "Airline", "HotelGST"]}
            onChange={(value) => {
              console.log(value); // string
            }}
            value={activeView}
          />
        </div> */}
        {/* <div className="GraphContainerLayout">
          <div className="GraphWidgetContainer">
            <div className="WidgetHeader">
              <PieChartOutlined style={{ marginRight: 8 }} />
              <Typography variant="caption" weight={500}>
                Total Active Users
              </Typography>
            </div>
            <div className="WidgetContent">
              <div className="GraphContainer">
                <PieChart
                  data={[
                    {
                      asset: "Internal Users",
                      amount: analytics?.activeUsers?.internal,
                    },
                    {
                      asset: "External Users",
                      amount: analytics?.activeUsers?.external,
                    },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="GraphWidgetContainer">
            <div className="WidgetHeader">
              <LineChartOutlined style={{ marginRight: 8 }} />
              <Typography variant="caption" weight={500}>
                User Login Activity
              </Typography>
            </div>
            <div className="WidgetContent">
              <div className="GraphContainer">
                <LineChart data={analytics?.logs} />
              </div>
            </div>
          </div>

          <div className="GraphWidgetContainer">
            <div className="WidgetHeader">
              <PieChartOutlined style={{ marginRight: 8 }} />
              <Typography variant="caption" weight={500}>
                Domain Based Login
              </Typography>
            </div>
            <div className="WidgetContent">
              <div className="GraphContainer">
                <PieChart
                  data={getDomainBasedLoginInfo(analytics?.userslist)}
                />
              </div>
            </div>
          </div>
        </div> */}
        {activeView === "2" ? (
          <div className="GraphContainerLayout">
            <div className="GraphWidgetContainer">
              <div className="WidgetHeader">
                <LineChartOutlined style={{ marginRight: 8 }} />
                <Typography variant="caption" weight={500}>
                  Email Delivery Rate
                </Typography>
              </div>
              <div className="WidgetContent">
                <div className="GraphContainer">
                  <AreaChart data={analytics?.successFailedData} />
                </div>
              </div>
            </div>

            <div className="GraphWidgetContainer">
              <div className="WidgetHeader">
                <PieChartOutlined style={{ marginRight: 8 }} />
                <Typography variant="caption" weight={500}>
                  Failed Email Breakup
                </Typography>
              </div>
              <div className="WidgetContent">
                <div className="GraphContainer">
                  <PieChart
                    data={[
                      {
                        asset: "Bounced",
                        amount: analytics?.failedBreakupData?.[0]?.count,
                      },
                      {
                        asset: "Dropped",
                        amount: analytics?.failedBreakupData?.[1]?.count,
                      },
                      {
                        asset: "Deffered",
                        amount: analytics?.failedBreakupData?.[2]?.count,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="GraphWidgetContainer">
              <div className="WidgetHeader">
                <PieChartOutlined style={{ marginRight: 8 }} />
                <Typography variant="caption" weight={500}>
                  Email Sent Vs Invoice Received
                </Typography>
              </div>
              <div className="WidgetContent">
                <div className="GraphContainer">
                  <PieChart
                    data={[
                      {
                        asset: "Email Sent",
                        amount: analytics?.logsWithEvent?.[0]?.uniqueCount,
                      },
                      {
                        asset: "Invoice Received",
                        amount: analytics?.total_invoices?.[0]?.uniqueCount,
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="GraphContainerLayout">
            <iframe
              src="https://eu.mixpanel.com/p/67aFcd4GozTJDe8xK5neAg"
              style={{ width: "100%", border: "none" }}
              title="Mixpanel Dashboard"
              allowFullScreen
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            />
          </div>
        )}
      </div>
    </div>
  );
}
