import logo from "./logo.svg";
import "./App.css";
import MasterLayout from "./shared/MasterLayout";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#0A8394",
        },
      }}
    >
      <MasterLayout />
    </ConfigProvider>
  );
}

export default App;
