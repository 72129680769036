import { createRoot } from "react-dom/client";
import { AgCharts } from "ag-charts-react";
import { useState } from "react";

function getData(data) {
  return [
    { asset: "Internal Users", amount: data?.internal },
    { asset: "External Users", amount: data?.external },
  ];
}

function PieChart(props) {
  console.log("props:", props);
  const [options, setOptions] = useState({
    series: [
      {
        type: "pie",
        angleKey: "amount",
        legendItemKey: "asset",
      },
    ],
  });

  return (
    <AgCharts
      options={{
        ...options,
        data: props.data,
      }}
      style={{ height: "70%" }}
    />
  );
}

export default PieChart;
